import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2019-01-01T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://tolkienaboutscifi.wordpress.com/2017/09/20/layered-themes-in-the-broken-earth/"
      }}><img parentName="a" {...{
          "src": "/reading/broken-earth-syenite.png",
          "alt": "Essun"
        }}></img></a></p>
    <a href="https://twitter.com/kalidraws/status/815324289037516802?s=20"><img width="34.45%" src="/reading/broken-earth-ruby.jpg" /></a>
    <a href="https://www.artstation.com/artwork/3V1og"><img width="32.45%" src="/reading/broken-earth-essun.jpg" /></a>
    <a href="https://thebrokenearth.tumblr.com/post/178528451538/jmalkki-alabaster-madonna-he-has-skin-so?is_highlighted_post=1"><img width="32.6%" src="/reading/broken-earth-alabaster.jpeg" /></a>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`Broken Earth series`}</strong>{`: The Fifth Season, The Obelisk Gate, and The Stone Sky`}</li>
      <li parentName="ul">{`Author: Nora K. Jemisin`}</li>
    </ul>
    <p>{`My rating: ★★★★★ `}</p>
    <p>{`I was skeptical on whether I'd enjoy these books at first as they are more "fantasy" than I usually go for, I'm so glad I got over that as these books are an incredible gift to world. I read all three in a row and felt a little lost when I finished the last one in the series as I felt I'd been in that world for so long.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      